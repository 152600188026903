import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  backgroundColors: {
    primary: "white",
    secondary: "#222B37",
  },
  colors: {
    primary: "#1C2653",
    secondary: "#FCCF11",
    tertiary: "#ef1d96",
    border: "#EAE8E4",
    blue: "#1C2653",
    yellow: "#FCCF11",
    gold: "#EEAA1F",
    green: "#7DBF42",
    white: "#FFFFFF",
  },
  textColors: {
    primary: "#1C2653",
    secondary: "#999FAF",
    tertiary: "#FCCF11",
  },
  fontWeights: {
    regular: "400",
    medium: "500",
    bold: "700",
    black: "900",
  },
  fonts: {
    primary: "'Roboto', Arial, Helvetica, sans-serif",
    secondary: "'Bebas Neue', sans-serif",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
