import React from "react";
import styled from "styled-components";

const Raffle = () => {
  return (
    <section id="raffle">
      <Content>
        <Container>
          <Headline>
            <Title>Raffle</Title>
            <Subtitle>Announced on March 15</Subtitle>
          </Headline>
          <p>
            All names/sheets should be turned in with the funds raised. These
            names will be put into a randomized website to spit out 3 winners of
            $100 gift cards (4 if funds exceed goal).
          </p>
          <p>This raffle can be done live on YouTube.</p>
          <p>
            All winners should be notified by the player/family who collected
            the donation.
          </p>
        </Container>
      </Content>
    </section>
  );
};

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 100px 0;
  background-color: ${(props) => props.theme.colors.yellow};

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 40px;

  p {
    color: ${(props) => props.theme.colors.blue};
    font-size: 18px;
    max-width: 800px;
    text-align: center;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    padding: 0 20px;
  }
`;

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: 0;
  font-size: 84px;

  text-shadow: inset 2px 2px ${(props) => props.theme.colors.gold};

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 70px;
  }
`;

const Subtitle = styled.h5`
  color: white;
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 0;
  text-shadow: 1px 2px 0 ${(props) => props.theme.colors.gold};
`;

// const Button = styled.span`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   border-radius: 40px;
//   outline: none;
//   border: none;
//   color: ${(props) => props.theme.colors.blue};
//   font-family: ${(props) => props.theme.fonts.secondary};
//   font-weight: bold;
//   font-size: 20px;
//   line-height: 1;
//   height: 54px;
//   padding-top: 2px;
//   margin-top: 30px;
//   width: 300px;
//   letter-spacing: 1px;
//   cursor: pointer;
//   box-shadow: 0 0 10px ${(props) => props.theme.colors.gold};

//   &:hover {
//     box-shadow: 0 0 20px ${(props) => props.theme.colors.gold};
//   }

//   @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
//     width: 300px;
//   }
// `;

export default Raffle;
