import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./index.css";
import HomePage from "./pages/Home";
import ErrorPage from "./pages/404";
import FundraiserPage from "./pages/Fundraiser";
import MainLayout from "./layouts/MainLayout";
import Theme from "./Theme";

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <Router>
        <Switch>
          <Route exact path="/404">
            <ErrorPage />
          </Route>
          <Route exact path="/fundraiser">
            <MainLayout internal>
              <FundraiserPage />
            </MainLayout>
          </Route>
          <Route
            exact
            path="/tryout"
            component={() => {
              window.location.replace("https://forms.gle/DWM8rPZW4aPJbSmf8");
              return null;
            }}
          />
          <Route exact path="/">
            <MainLayout>
              <HomePage />
            </MainLayout>
          </Route>
          <Route
            render={({ location }) => (
              <Redirect to={{ pathname: "/404", state: { from: location } }} />
            )}
          />
        </Switch>
      </Router>
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);
