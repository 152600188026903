import mascot from "../images/mascot.png";

import React from "react";
import styled from "styled-components";

const CoachNote = () => (
  <section>
    <Content>
      <Photo>
        <img src={mascot} alt="" />
      </Photo>
      <TextWrapper>
        <Title>Manager's Note</Title>
        <p>
          The Bananas will always be about these players. We promise to listen,
          teach and build up each player to shape them as better baseball
          players and better humans. Our goal is for players to love baseball
          and to use the sport as a vehicle to build confidence and learn how to
          work hard to achieve personal goals and team success.
        </p>
      </TextWrapper>
    </Content>
  </section>
);

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 100px 0;
  background-color: ${(props) => props.theme.colors.yellow};

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Photo = styled.div`
  display: flex;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
  box-shadow: 2px 1px 0 white;
  background-color: ${(props) => props.theme.colors.green};
  margin-right: 50px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 992px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const TextWrapper = styled.div`
  max-width: 720px;

  p {
    color: ${(props) => props.theme.colors.blue};
    margin-bottom: 0;
  }

  @media only screen and (max-width: 550px) {
    padding: 0 30px;
    text-align: center;

    p {
      font-size: 18px;
    }
  }
`;

const Title = styled.h4`
  font-size: 24px;
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: 6px;
  letter-spacing: 0.5px;

  @media only screen and (max-width: 550px) {
    font-size: 36px;
  }
`;

export default CoachNote;
