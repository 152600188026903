import hero from "../images/foco-bananas-hero.jpg";

import React from "react";
import styled from "styled-components";

const Hero = () => (
  <section>
    <Background>
      <BackgroundImage image={hero} />
    </Background>
    <Content>
      <Column>
        <Title>
          <span>the FOCO</span>
          <span>Bananas</span>
        </Title>
        <Subtitle>
          <span></span>
          <p>Forysth County, GA</p>
          <span></span>
        </Subtitle>
      </Column>
    </Content>
  </section>
);

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-position: center;
  opacity: 0.5;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.blue};
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 120px 30px;
`;

const Column = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 400px;
  margin-right: 20%;

  @media only screen and (max-width: 550px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 120px;
  line-height: 0.9em;
  text-align: center;
  margin-bottom: 10px;

  span {
    display: block;

    &:first-child {
      font-size: 0.99em;
      text-shadow: 0.03em 0.03em 0 ${(props) => props.theme.colors.blue};
    }

    &:last-child {
      color: ${(props) => props.theme.colors.yellow};
      text-shadow: 0.04em 0.04em 0 ${(props) => props.theme.colors.blue};
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 86px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  text-shadow: 1px 1px 10px ${(props) => props.theme.colors.blue};

  span {
    flex: 1;
    background-color: white;
    height: 1px;
  }
  p {
    font-size: 24px;
    color: white;
    padding: 0 20px;
    margin: 0;
    font-family: ${(props) => props.theme.fonts.secondary};
  }
`;

export default Hero;
