import calendar from "../../images/FoCoBananas-CalendarFundraiser.jpg";
import mascot from "../../images/logos/FoCoBananas-Mascot.svg";

import React from "react";
import styled from "styled-components";

const HowItWorks = () => (
  <section id="howItWorks">
    <Content>
      <Row>
        <Column>
          <ImageWrapper>
            <Image src={calendar} />
          </ImageWrapper>
          <PhotoCredit>
            <strong>Desktop:</strong> Right-click this image and select "Save
            Image As..."
          </PhotoCredit>
          <PhotoCredit>
            <strong>Mobile:</strong> Hold down this image and tap "Save to
            Photos"
          </PhotoCredit>
        </Column>
        <Column>
          <TextWrapper>
            <TitleWrapper>
              <Logo src={mascot} />
              <Title>
                How it Works <span>Sell out your Calendar</span>
              </Title>
            </TitleWrapper>
            <p>
              Each day on the calendar represents a dollar amount. Supporters
              offer to pay a date's value on your calendar.
            </p>
            <p>
              For each day purchased, the supporter's name will be entered into
              a raffle. The value of the day purchased does not increase your
              chances to win.
            </p>
          </TextWrapper>
        </Column>
      </Row>
    </Content>
  </section>
);

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 140px 0 110px;
  background-color: white;

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
  }
`;

const Row = styled.div`
  max-width: 1600px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;

  padding: 0 30px;

  &:first-child {
    justify-content: flex-end;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    padding: 0 20px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 500px;
  max-width: 500px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    transform: rotate(-3deg);
    background-color: ${(props) => props.theme.colors.yellow};
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    transform: rotate(3deg);
    background-color: ${(props) => props.theme.colors.blue};
  }

  @media only screen and (max-width: 550px) {
    width: auto;
  }
`;

const Image = styled.img`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px dashed ${(props) => props.theme.colors.green};
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.blue};
  line-height: 1.1em;
  text-indent: 60px;

  span {
    display: block;
    font-size: 1.3em;
    color: ${(props) => props.theme.colors.yellow};
    text-shadow: 2px 2px 0 ${(props) => props.theme.colors.gold};
    text-indent: 0;
    text-decoration: none;

    &:hover {
      text-shadow: 2px 2px 0 ${(props) => props.theme.colors.green};
    }
  }

  @media only screen and (max-width: 1099px) {
    a {
      font-size: 1em;
      text-shadow: 1px 1px 0 ${(props) => props.theme.colors.gold};
    }
  }

  @media only screen and (max-width: 992px) {
    text-indent: 90px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 40px;
    span {
      text-align: center;
    }
  }
`;

const PhotoCredit = styled.p`
  margin: 20px 0 0;
  text-align: center;
  max-width: 500px;
  font-size: 12px;

  &:last-child {
    margin-top: 10px;
  }

  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    font-size: 14px;

    strong {
      display: block;
    }

    &:last-child {
      margin-top: 20px;
    }
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  display: inline-block;

  @media only screen and (max-width: 992px) {
    margin: 0 auto;

    h1 {
      margin-bottom: 20px;
    }
  }
`;

const TextWrapper = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 600px;

  p {
    line-height: 1.6;
  }

  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;

    margin-top: 80px;

    p {
      text-align: center;
    }
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-27px);
  display: block;
  width: 64px;
  object-fit: contain;

  @media only screen and (max-width: 992px) {
    left: 30px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    transform: translateY(-34px);
  }
`;

export default HowItWorks;
