import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import FundraiserIntro from "../components/fundraiser/FundraiserIntro";
import HowItWorks from "../components/fundraiser/HowItWorks";
import Raffle from "../components/fundraiser/Raffle";
import Supporters from "../components/fundraiser/Supporters";

const Fundraiser = () => {
  return (
    <Page>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
        <meta
          property="og:image"
          name="twitter:image"
          content="https://focobananas.com/images/FoCoBananas-CalendarFundraiser.jpg"
        />
        <meta
          itemprop="image"
          content="https://focobananas.com/images/FoCoBananas-CalendarFundraiser.jpg"
        />
        <title>FoCo Bananas | Fundraiser</title>
      </Helmet>
      <FundraiserIntro />
      <HowItWorks />
      <Supporters />
      <Raffle />
    </Page>
  );
};

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.blue};
`;

export default Fundraiser;
