import React from "react";
import styled from "styled-components";

import Hero from "../components/Hero";
import InspiredBy from "../components/InspiredBy";
import CoreValues from "../components/CoreValues";
import CoachNote from "../components/CoachNote";
import Travel from "../components/Travel";

const Home = () => {
  return (
    <Page>
      <Hero />
      <Travel />
      <InspiredBy />

      <CoreValues />
      <CoachNote />
    </Page>
  );
};

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #1c2653;
`;

export default Home;
