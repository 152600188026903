import React from "react";
import styled from "styled-components";

const FundraiserIntro = () => {
  return (
    <Section id="fundraiser">
      <Content>
        <Headline>
          <Title>Fundraiser</Title>
          <Subtitle>Pick a Date to Donate</Subtitle>
        </Headline>
        <p>
          The goal of our fundraiser is to purchase expensive training equipment
          like a pitching machine and a portable mound. These are traditionally
          available to teams run by businesses - we're a group of families
          trying to provide a similar experience. These items will tremendouly
          help our players advance to the next level.
        </p>
        <p>
          <strong>Goal: To raise at least $3500 in February.</strong>
        </p>
      </Content>
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 100px 0 80px;
  background-color: ${(props) => props.theme.colors.blue};

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 40px;

  p {
    color: white;
    font-size: 18px;
    max-width: 800px;
    text-align: center;

    strong {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.yellow};
  margin-bottom: 0;
  font-size: 84px;

  text-shadow: inset 2px 2px ${(props) => props.theme.colors.gold};

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 60px;
  }
`;

const Subtitle = styled.h5`
  color: white;
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 0;
`;

export default FundraiserIntro;
