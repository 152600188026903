import React from "react";
import styled from "styled-components";

const Travel = () => {
  const btnClick = () => {
    window.open("https://forms.gle/DWM8rPZW4aPJbSmf8");
  };

  return (
    <section id="travel">
      <Content>
        <Container>
          <Headline>
            <Title>15U Tryouts</Title>
            <Subtitle>Fall 2024 / Summer 2025</Subtitle>
          </Headline>
          <p>
            Tryouts for the 15U FOCO Bananas will be held at{" "}
            <a href="https://maps.app.goo.gl/NvQ5tqMKDTqgAhZQ6" target="_blank">
              Lanierland Park on Field 9
            </a>
            :
          </p>
          <ul>
            <li>Wednesday, Aug 7th at 6:00pm</li>
          </ul>
          <p>
            For questions and more information, please contact Coach Chris (330)
            730-9328. Pre-register for tryouts using the button below.
          </p>
          <Button onClick={btnClick}>Register for Tryouts</Button>
        </Container>
      </Content>
    </section>
  );
};

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 100px 0;
  background-color: ${(props) => props.theme.colors.yellow};

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 40px;

  p {
    color: ${(props) => props.theme.colors.blue};
    font-size: 18px;
    line-height: 1.5;
    max-width: 650px;
    text-align: center;

    a {
      color: ${(props) => props.theme.colors.blue};
      text-decoration: underline;
    }
  }

  ul {
    color: ${(props) => props.theme.colors.blue};
    font-size: 18px;
    margin: 10px 0;

    li {
      margin-bottom: 15px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    padding: 0 20px;
  }
`;

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: 0;
  font-size: 84px;

  text-shadow: inset 2px 2px ${(props) => props.theme.colors.gold};

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 70px;
  }
`;

const Subtitle = styled.h5`
  color: white;
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 0;
  text-shadow: 1px 2px 0 ${(props) => props.theme.colors.gold};
`;

const Button = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 40px;
  outline: none;
  border: none;
  color: ${(props) => props.theme.colors.blue};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  height: 54px;
  padding-top: 2px;
  margin-top: 30px;
  width: 300px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.gold};

  &:hover {
    box-shadow: 0 0 20px ${(props) => props.theme.colors.gold};
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    width: 300px;
  }
`;

export default Travel;
