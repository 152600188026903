import logo from "../images/logos/FoCoBananas-Diamond.svg";

import React from "react";
import styled from "styled-components";

const ErrorPage = () => (
  <Container>
    <Logo src={logo} />
    <Content>
      <h1>Page Not Found.</h1>
      <p>
        There was no page found at this address. Please check your URL and try
        again.
      </p>
      <Button href="/">Go Home</Button>
    </Content>
  </Container>
);

const Button = styled.a`
  display: block;
  width: 180px;
  height: 48px;
  line-height: 48px;
  background: ${(props) => props.theme.colors.yellow};
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue};
  letter-spacing: 0.5px;
  margin-top: 24px;
  cursor: pointer;
  text-decoration: none;

  @media only screen and (max-width: 550px) {
    align-self: center;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: 30px;
`;

const Content = styled.div`
  flex: 1;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px;
  margin-left: -20px;

  @media only screen and (max-width: 550px) {
    width: 180px;
    align-self: center;
  }
`;

export default ErrorPage;
