import React from "react";
import styled from "styled-components";

const Footer = ({ mode = "internal" }) => (
  <Container mode={mode}>
    <Copyright>{`© ${new Date().getFullYear()} FOCO Bananas`}</Copyright>
  </Container>
);

const Copyright = styled.p`
  font-size: 11px;
  color: ${(props) => props.theme.colors.yellow};
  margin-bottom: 0;

  span {
    margin-left: 14px;
  }

  span,
  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: ${(props) => props.theme.colors.yellow};
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 16px;

    span {
      margin-top: 10px;
    }

    span,
    a {
      display: block;
      margin-left: 0;
    }
  }
`;

const Container = styled.footer`
  background-color: ${(props) => props.theme.colors.blue};
  text-align: center;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  border-top: 2px solid ${(props) => props.theme.colors.gold};
`;

export default Footer;
