import React from "react";
import styled from "styled-components";
import { MdOutlinePrint } from "react-icons/md";
import pdf from "../../files/FoCoBananas-PickADateToDonate.pdf";

const Supporters = () => (
  <section id="supporters">
    <Content>
      <Container>
        <Headline>
          <Title>Supporters</Title>
          <Subtitle>How to quickly find those willing to donate</Subtitle>
        </Headline>

        <Values>
          <Column>
            <Point>
              <h4>
                Facebook <span>Fastest</span>
              </h4>
              <p>
                The <strong>fastest</strong> way to sell out your calendar is on
                social media.
              </p>
              <ol>
                <li>Post the image to Facebook.</li>
                <li>
                  Ask for help. Here's a script you can use with your post:
                  <em>
                    🚨 Please consider helping <strong>[PLAYER NAME]</strong>
                    's baseball team. See photo below for fundraiser details.
                    Thank you! Venmo: <strong>[@username]</strong>, Cash/Check
                  </em>
                </li>
                <li>
                  <strong>IMPORTANT:</strong> Stay active in the comments
                  thanking supporters and liking comments - this trains the
                  algorithm that your post has activity and should stay featured
                  in news feed. If two of you can thank each supporter, even
                  better.
                </li>
                <li>
                  The more activity on your post, the better. If someone asks
                  for a date and it's taken, just comment back other dates that
                  are available.
                </li>
                <li>
                  After 48 hours, consider another post with a new image with
                  dates crossed off saying "only a few days remain".
                </li>
              </ol>
              <p>
                Don't collect emails. You can contact these donors through
                Facebook if they win.
              </p>
            </Point>
          </Column>
          <Column>
            <Point>
              <h4>Neighborhood / Community</h4>
              <p>
                Have your player print off the image and ask neighbors for
                support. Don't forget to{" "}
                <strong>collect each name/email</strong> of anyone who donates.
              </p>
              <p>
                Here is a printable PDF that you can use for tracking or
                in-person donations:
              </p>
              <Link>
                <MdOutlinePrint />
                <a href={pdf} target="_blank">
                  FoCo Bananas Pick-a-Date-to-Donate Printable PDF
                </a>
              </Link>
            </Point>
            <Point>
              <h4>Work / Office</h4>
              <p>
                If you haven't seen these campaigns floating around -- they're
                everywhere. Colleagues likely know the drill and would be
                willing to help out.
              </p>
            </Point>
          </Column>
        </Values>

        <Footer>
          <p>
            Collect each name/email of anyone who donates. This helps us contact
            them if they win. For Facebook donors, just mark them as "Facebook".
          </p>
        </Footer>
      </Container>
    </Content>
  </section>
);

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 140px 0 80px;
  background-color: ${(props) => props.theme.colors.blue};

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

const Values = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 20px 30px;

  h4 {
    font-size: 32px;
    color: ${(props) => props.theme.colors.green};
    margin-bottom: 10px;
    letter-spacing: 2px;

    span {
      color: ${(props) => props.theme.colors.yellow};
      font-size: 20px;
      font-weight: bold;
      margin-left: 12px;
    }
  }
  p,
  ol,
  li,
  svg {
    color: white;
  }

  ol {
    margin-top: -5px;
    padding-inline-start: 30px;
  }

  li {
    padding-left: 8px;
    margin: 10px 0;
    line-height: 1.4;

    em {
      display: block;
      margin: 14px 0 20px 10px;
      font-size: 0.9rem;

      strong {
        font-weight: normal;
        color: ${(props) => props.theme.colors.yellow};
      }
    }
  }
`;

const Link = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    color: white;
    font-weight: 600;
    font-size: 0.95em;
  }
  svg {
    font-size: 1.2em;
    margin-right: 10px;
  }
`;

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.yellow};
  margin-bottom: 0;
  font-size: 84px;

  text-shadow: inset 2px 2px ${(props) => props.theme.colors.gold};

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 60px;
  }
`;

const Subtitle = styled.h5`
  color: white;
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 0;
  padding: 0 30px;
`;

const Footer = styled.div`
  margin-top: 30px;
  padding: 0 40px;
  p {
    text-align: center;
    color: white;
    font-weight: bold;
    color: ${(props) => props.theme.colors.yellow};
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    p {
      text-align: left;
    }
  }
`;

export default Supporters;
