import logo from "../images/logos/FoCoBananas-Diamond.svg";

import React, { useState } from "react";
import styled from "styled-components";

import Footer from "../components/Footer";

const MainLayout = ({ internal, children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      <Header centerContent={internal}>
        <a href="/">
          <LogoWrapper>
            <Logo src={logo} />
          </LogoWrapper>
        </a>
        {internal ? null : (
          <>
            <MobileBtn open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
              <svg
                aria-hidden="true"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
              >
                <g>
                  <path d="M5 13h90v14H5z" />
                  <path d="M5 43h90v14H5z" />
                  <path d="M5 73h90v14H5z" />
                </g>
              </svg>
            </MobileBtn>
            <Links open={menuOpen}>
              <Link href="#values" onClick={() => setMenuOpen(false)}>
                Core Values
              </Link>
              <Link href="#travel" onClick={() => setMenuOpen(false)}>
                15U Travel
              </Link>
              <Link
                href="https://thesavannahbananas.com/merchandise/"
                target="_blank"
                rel="noreferrer"
                onClick={() => setMenuOpen(false)}
              >
                Team Shop
              </Link>
              <Link
                href="https://forms.gle/DWM8rPZW4aPJbSmf8"
                target="_blank"
                rel="noreferrer"
                onClick={() => setMenuOpen(false)}
              >
                Tryouts <span></span>
              </Link>
            </Links>
          </>
        )}
      </Header>
      <Content>{children}</Content>
      <Footer mode="internal" />
    </Container>
  );
};

const MobileBtn = styled.button`
  display: none;
  border: none;
  border-radius: 0;
  background: transparent;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  padding: 1em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  font-size: 0.875em;
  padding: 1em;
  margin-left: auto;

  svg {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    position: relative; /* Align more nicely with capital letters */
    top: -0.0625em;

    transform: scale(1.75);
  }

  g {
    opacity: 1;
    transform: rotate(0) translateY(0) translateX(0);
    transform-origin: 1em 1em;
  }

  path {
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;

    &:first-child {
      transform-origin: 1em 2.5em;
    }
    &:last-child {
      transform-origin: 1em 4.5em;
    }
  }

  ${({ open }) =>
    open &&
    `
    path {
      opacity: 0;
      &:first-child {
        transform: rotate(45deg) translateY(0) translateX(0);
        opacity: 1;
      }
      &:last-child {
        transform: rotate(-45deg) translateY(0em) translateX(0em);
        opacity: 1;
      }
    }
  `}

  @media only screen and (max-width: 992px) {
    display: inline-block;
  }
`;

const Links = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 992px) {
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    display: ${(props) => (props.open ? "block" : "none")};

    background-color: ${(props) => props.theme.colors.yellow};
    border-top: 2px solid ${(props) => props.theme.colors.gold};

    box-shadow: 0 10px 0 10px rgba(28, 38, 83, 0.2);
    padding-top: 10px;
  }
`;

const Link = styled.a`
  position: relative;
  display: block;
  padding: 20px;
  margin: 0 10px;
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 0 ${(props) => props.theme.colors.gold};

  &:last-child {
    margin-right: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      width: 40px;
      height: 3px;
      border-radius: 8px;
      background-color: ${(props) => props.theme.colors.green};
    }
  }

  span {
    position: absolute;
    top: 20px;
    right: 8px;
    border-radius: 20px;
    width: 8px;
    height: 8px;
    background-color: white;
    box-shadow: 1px 1px 0 ${(props) => props.theme.colors.gold};
  }

  @media only screen and (max-width: 992px) {
    text-align: center;
    border-top: 1px dashed ${(props) => props.theme.colors.gold};

    &:first-child {
      border-top: none;
    }

    &:last-child {
      color: white;

      &::after {
        display: none;
      }
    }

    span {
      display: none;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  margin-top: 90px;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  position: relative;
  height: 80px;
  width: 200px;
`;

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 105px;
  z-index: 5;
  transform: translateZ(0);

  @media only screen and (max-width: 550px) {
    width: 130px;
  }
`;

const Header = styled.div`
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 40px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gold};
  background-color: ${(props) => props.theme.colors.yellow};
  width: 100%;

  ${({ centerContent }) =>
    centerContent &&
    `
    justify-content: center;

    ${LogoWrapper} {
      width: 130px;
      transform: translateX(-4px);
    }
  `}

  @media only screen and (max-width: 992px) {
    padding: 5px 20px;
  }
`;

export default MainLayout;
