import React from "react";
import styled from "styled-components";

const CoreValues = () => (
  <section id="values">
    <Content>
      <Container>
        <Headline>
          <Title>Core Values</Title>
          <Subtitle>Peelin it back</Subtitle>
        </Headline>

        <Values>
          <Row>
            <Point>
              <h4>Commitment</h4>
              <p>
                High school athletics are life's earliest teacher for instilling
                work ethic and discipline. Each player is required to put in the
                individual work to advance his skillset.
              </p>
            </Point>
            <Point>
              <h4>Respect</h4>
              <p>
                Coaches, umpires, opponents and teammates - baseball provides an
                opportunity to build character and represent yourself well. Good
                sportsmanship is required and celebrated.
              </p>
            </Point>
          </Row>
          <Row>
            <Point>
              <h4>Level Up</h4>
              <p>
                Sports teach us most about failure; how to deal with it and how
                to apply it. We believe every practice and every game is an
                opportunity to get better through max effort.
              </p>
            </Point>
            <Point>
              <h4>Team First</h4>
              <p>
                Leave your ego out of the dugout. Every player plays for the
                name on the front of the jersey. Pick up your teammates, play as
                a team, and support one another ... always.
              </p>
            </Point>
          </Row>
        </Values>
      </Container>
    </Content>
  </section>
);

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 140px 0 80px;
  background-color: ${(props) => props.theme.colors.blue};

  @media only screen and (max-width: 991px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

const Values = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 30px;

  h4 {
    font-size: 32px;
    color: ${(props) => props.theme.colors.green};
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  p {
    color: white;
  }
`;

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.yellow};
  margin-bottom: 0;
  font-size: 84px;

  text-shadow: inset 2px 2px ${(props) => props.theme.colors.gold};

  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
    font-size: 60px;
  }
`;

const Subtitle = styled.h5`
  color: white;
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 0;
`;

export default CoreValues;
